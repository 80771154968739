<template>
  <div class="index">
    <!-- {{ nowDate }},{{ nowTime }} -->
    <nav-bar ref="son" />
    <index-banner />
    <content-img />
    <hot-goods />
    <invite />
    <daifa-guide />
    <bottom-footer />
    <back-top />
    <!-- <router-link to="/newView"><button >跳转</button></router-link> -->
    <el-dialog :title="loadNoticeByPos.title" :visible.sync="dialogVisible" :modal-append-to-body="false" :lock-scroll="false" width="40%" custom-class="el-dialog1" :center="true" v-show="isshow" :modal="false">
      <div v-html="loadNoticeByPos.content" data-v-0c77f66e ></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="out" size="small">下次再说</el-button>
        <el-button type="primary" @click="msg" size="small">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import IndexBanner from 'views/index/childComps/IndexBanner'
import HotGoods from 'views/index/childComps/HotGoods'
import ContentImg from 'views/index/childComps/ContentImg'
import Invite from 'views/index/childComps/Invite'
import DaifaGuide from 'views/index/childComps/DaifaGuide'
import BottomFooter from 'components/bottomfooter/BottomFooter'
import BackTop from 'components/backtop/BackTop'
import { loadNoticeByPos } from '@/network/api.js'
import Cookies from 'js-cookie'
// import Vue from 'vue'
export default {
  components: {
    NavBar,
    IndexBanner,
    HotGoods,
    Invite,
    DaifaGuide,
    BottomFooter,
    ContentImg,
    BackTop
  },
  data () {
    return {
      nowDate: null, // 存放年月日变量
      nowTime: null, // 存放时分秒变量
      currentTime: new Date(), // 获取当前时间
      timer: null, // 定时器
      isshow: true,
      loadNoticeByPos: [],
      dialogVisible: false
    }
  },
  created () {
    this.timer = setInterval(this.getTime, 1000)
    // 每20秒刷新一次
    this.timer = setInterval(() => {
      this.isshow = true
      // eslint-disable-next-line no-unused-expressions
      Cookies.remove('ads')
      Cookies.remove('ad')
      localStorage.removeItem('msg')
      localStorage.removeItem('mesg')
    }, 1000 * 60 * 60 * 6)
    const vers = localStorage.getItem('msg')
    if (!vers) {
      if (!document.cookie.includes('ads=')) {
        this.dialog()
        this.setcookie()
        this.isshow = true
      } else {
        this.isshow = true
      }
    }
  },
  methods: {
    getTime () {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
      // eslint-disable-next-line no-unused-vars
      const str = ''
      if (this.hour > 12) {
        this.hour -= 12
        this.str = ' AM'
      } else {
        this.str = ' PM'
      }
      this.month = check(month)
      this.day = check(day)
      this.hour = check(hour)
      this.minute = check(minute)
      this.second = check(second)
      function check (i) {
        const num = (i < 10) ? ('0' + i) : i
        return num
      }
      this.nowDate = year + '年' + this.month + '月' + this.day + '日'
      this.nowTime = this.hour + ':' + this.minute + ':' + this.second + this.str
    },
    setcookie () {
      const d = new Date()
      // d.setTime(d.getTime() + 24 * 60 * 60 * 1000)// 24小时
      d.setTime(d.getTime() + 6 * 60 * 60 * 1000) // 12小时
      // d.setTime(d.getTime() + 1 * 60 * 1000);//1分钟
      // ad=popup-ad   键值对形式：name=key   expires 有效期
      document.cookie = 'ads=popup-ads;expires= ' + d.toGMTString()
      const result = document.cookie
      return result
    },
    dialog () {
      loadNoticeByPos({ position: 'index' }).then((res) => {
        this.loadNoticeByPos = res.data
        this.dialogVisible = true
      })
    },
    msg () {
      this.dialogVisible = false
      window.localStorage.setItem('msg', '我知道了')
    },
    out () {
      this.dialogVisible = false
      window.localStorage.removeItem('msg')
      // this.setcookie()
      Cookies.remove('ads')
    }
  },
  // 可以判断如果定时器还在运行就关闭 或者直接关闭
  // 在Vue实例销毁前，清除定时器
  beforeDestroy () { if (this.timer) { clearInterval(this.timer) } }
}
</script>

<style scoped src="styles/views/index/index.css">
</style>
<style lang="scss" scope>
// .el-dialog {
  // max-height: 700px;
  // overflow: auto;
  // max-height: auto;
// }
.el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 25px 25px 30px;
    height: 450px;
}
// .el-dialog__body {
//   height: 400px;
//   overflow: auto;
// }

.footer {
  margin-top: 20px;
}
</style>
