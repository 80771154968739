<template>
  <div class="container-img">
    <div class="container">
      <el-row >
        <el-col :span="6" v-for="(item, index) in goodslist" :key="index" >
          <!-- <el-card > -->
          <img :src="item.localimg" class="image"  @click="skipClick(index)"/>
          <!-- </el-card> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getIndexGoods, getAdsInfo } from 'network/api'

export default {
  components: {},
  data () {
    return {
      currentDate: new Date(),
      goodslist: []
    }
  },
  created () {
    getIndexGoods().then((res) => {
      this.leftGoods = res.data[0]
      this.goods = res.data.splice(1, 8)
    })
    getAdsInfo({ adstag: 'banner_end' }).then((res) => {
      this.goodslist = res.data.adslist
    })
  },
  methods: {
    toMall () {
      this.$router.push('/goods')
    },
    skipClick (index) {
      window.open(this.goodslist[index].clickhref, '_blank')
    }
  }
}
</script>

<style scoped >
.container-img {
  background: #f2f2f2;
  margin-top: -10px;
}
.el-col-6 /deep/ {
  width: 25%;
  margin-top: 25px;
}
.el-row {
    margin-bottom: 0px;
}
.image {
  width: 290px;
  height: 150px;
  border-radius: 5px;
  display: block;
}
</style>
