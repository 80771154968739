<template>
  <div class="content">
    <el-dialog data-v-3d8e05e2 :visible.sync="dialogFormVisible" :show-close="true" width="46%" :close-on-click-modal="false" :before-close="handleClose" :close-on-press-escape="false">
      <el-divider content-position="left">选择商品信息</el-divider>
      <div style="">
        <el-select v-model="goodsText" placeholder="请选择仓库" @change="getStorage" v-if="show"> <el-option v-for="item in radio1" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select><span style="margin-left:10px;"></span>
        <el-select data-v-91594155 v-model="storageText" placeholder="请选择平台" @change="getStorage" v-if="show">
          <el-option v-for="item in radio2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-button data-v-6d4a4e1e @click="colse">取 消</el-button>
        <el-button data-v-6d4a4e1e type="primary" @click="go">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { loadstoragebygoods, loadPlatByStorage } from '@/network/api'
export default {
  data() {
    return {
      dialogFormVisible: false,
      radio1: [],
      radio2: [],
      goodsText: '',
      storageText: '',
      item: null,
      show: true
    }
  },
  methods: {
    colse() {
      this.goodsText = null
      this.storageText = null
      this.dialogFormVisible = false
    },
    handleClose() {
      this.goodsText = null
      this.storageText = null
      this.dialogFormVisible = false
    },
    // eslint-disable-next-line camelcase
    dialogShow(item) {
      this.radio1 = []
      // console.log(goods_id)
      // eslint-disable-next-line no-unused-expressions
      this.item = item
      loadstoragebygoods({ goods_id: item.id }).then(res => {
        // console.log(res)
        if (res.code === 1) {
          this.radio1 = res.data
          this.dialogFormVisible = true
        } else if (res.code === 0) {
          this.$message.warning(res.msg)
        }
      })
    },
    go() {
      // eslint-disable-next-line no-unused-vars
      const shopId = {
        storage_id: this.storageText,
        goods_id: this.goodsText
      }
      // console.log(this.storageText)
      if (this.storageText === '' || this.goodsText === '') {
        this.$message({ message: '请选择商品信息', type: 'warning' })
        return
      }
      this.$router.push({
        path: '/orderAdmin/purchase',
        query: {
          shopId,
          item: this.item
        }
      })
      // this.dialogFormVisible = false
    },
    goods_id() {
      // console.log('id')
    },
    // 下拉框选中值变化
    getStorage() {
      loadPlatByStorage({
        storage_id: this.goodsText
      }).then(res => {
        // console.log(res)
        if (res.code === 1) {
          this.radio2 = res.data
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
// .content >>> .el-dialog__body {
//   padding-top: 0 !important;
// }
.el-select {
  width: 330px;
}
.el-button[data-v-6d4a4e1e] {
  width: 130px;
  margin-left: 10px;
}
.el-dialog {
  height: 536px !important;
}
.el-dialog__wrapper {
  max-height: 536px !important;
}
.el-select[data-v-91594155] {
  width: 200px;
  padding: 10px 0 10px;
}
// .el-dialog-div {
//   height: 20vh;
//   overflow: auto;
// }
</style>
