<template>
  <div class="daifa">
    <div class="container-full daifa-zhinan">
      <div class="container content">
        <div class="title">代发指南</div>
        <div class="tips-list">
          <div class="line"></div>

          <div class="tips-item">
            <div class="tips-item-t">
              <div class="tips-item-t-icon icon-one"></div>
            </div>
            <div class="tips-item-b">
              <div class="tips-item-b-title">选择来源</div>
              <div class="tips-item-b-p">选择数据来源平台 ，支持天猫、淘宝、阿里巴巴、拼多多、京东等各大平台</div>
            </div>
          </div>

          <div class="tips-item">
            <div class="tips-item-t">
              <div class="tips-item-t-icon icon-two"></div>
            </div>
            <div class="tips-item-b">
              <div class="tips-item-b-title">录入信息</div>
              <div class="tips-item-b-p">录入收件人信息，支持模板导入、人工填写</div>
            </div>
          </div>

          <div class="tips-item">
            <div class="tips-item-t">
              <div class="tips-item-t-icon icon-three"></div>
            </div>
            <div class="tips-item-b">
              <div class="tips-item-b-title">选择礼品</div>
              <div class="tips-item-b-p">根据需要选择礼品，满足礼品单各种需求</div>
            </div>
          </div>

          <div class="tips-item">
            <div class="tips-item-t">
              <div class="tips-item-t-icon icon-four"></div>
            </div>
            <div class="tips-item-b">
              <div class="tips-item-b-title">查看物流</div>
              <div class="tips-item-b-p">确认信息无误后，提交订单，可随时查看物流</div>
            </div>
          </div>
        </div>
        <div class="shopnow" @click="shopNow">立即选购礼品</div>
      </div>
    </div>
    <div class="container-full foot">
      <div class="container foot-list">
        <div class="foot-list-item">
          <div class="foot-list-l">
            <div class="foot-list-l-icon icon-one"></div>
          </div>
          <div class="foot-list-r">
            <span class="foot-list-r-t">正品保障</span>
            <span class="foot-list-r-b">正品保障，放心选购</span>
          </div>
        </div>

        <div class="foot-list-item">
          <div class="foot-list-l">
            <div class="foot-list-l-icon icon-two"></div>
          </div>
          <div class="foot-list-r">
            <span class="foot-list-r-t">天天低价</span>
            <span class="foot-list-r-b">天天低价，畅选无忧</span>
          </div>
        </div>

        <div class="foot-list-item">
          <div class="foot-list-l">
            <div class="foot-list-l-icon icon-three"></div>
          </div>
          <div class="foot-list-r">
            <span class="foot-list-r-t">多仓直发</span>
            <span class="foot-list-r-b">多仓直发，急速配达</span>
          </div>
        </div>

        <div class="foot-list-item">
          <div class="foot-list-l">
            <div class="foot-list-l-icon icon-four"></div>
          </div>
          <div class="foot-list-r">
            <span class="foot-list-r-t">品类齐全</span>
            <span class="foot-list-r-b">品类齐全，轻松选礼</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    shopNow () {
      this.$router.push('/goods')
      document.documentElement.scrollTop = 0
    }
  },
  mounted() {
    // 获取可视区域宽度
    const width = document.body.scrollWidth
    const app = document.querySelector('.daifa')
    // console.log(app.style)
    app.style.width = width + 'px'
  }
}
</script>

<style scoped src="styles/views/index/daifaguide.css">
</style>
