<template>
  <div class="container-banner">
    <div class="container-full banner-content">
      <el-carousel class="carousel" height="432px" arrow="hover" :interval="5000" trigger="click">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img  style="width:100%;background-repeat: repeat;height:432px"  :src="item.localimg" :url="link" @click="bannerClick(index)"  >
        </el-carousel-item>
      </el-carousel>
      <div class="banner-usermenu">
        <index-banner-item/>
      </div>
    </div>
  </div>
</template>

<script>
import IndexBannerItem from 'views/index/childComps/IndexBannerItem'
import { getAdsInfo } from 'network/api'

export default {
  components: { IndexBannerItem },
  data () {
    return {
      bannerList: [],
      link: ''
    }
  },
  created() {
    getAdsInfo({ adstag: 'ads' }).then((res) => {
      this.bannerList = res.data.adslist
      this.link = res.data.adslist
    })
  },
  methods: {
    bannerClick(index) {
      window.open(this.link[index].clickhref, '_blank')
    }
  },
  mounted() {
    // 获取可视区域宽度
    const width = document.body.scrollWidth
    const app = document.querySelector('.container-banner')
    // console.log(app.style)
    app.style.width = width + 'px'
  }
}
</script>

<style scoped src="styles/views/index/indexbanner.css">

</style>
